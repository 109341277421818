import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'

function checkVisible(elm) {
  let rect = elm.getBoundingClientRect()
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 200 || rect.top - viewHeight >= -200)
}

class ProjectsPage extends Component {

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    setTimeout(this.onScroll, 500)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (window.innerWidth >= 900) return
    let els = document.querySelectorAll('.project__archive a')
    for (let i = 0, l = els.length; i < l; i++) {
      checkVisible(els[i]) ?
        els[i].classList.add('active') :
        els[i].classList.remove('active')
    }
  }

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  renderItem = (el, i) => {
    return (
      <Link to={`/projects/${el.node.slug}`} key={i}>
        <figure>
          { this.renderImage(el.node.acf.feature_image) }
          <figcaption>
            <h4>{ el.node.title }</h4>
            <div dangerouslySetInnerHTML={{__html: el.node.acf.introduction }} />
          </figcaption>
        </figure>
      </Link>
    )
  }

  render() {
    let projects = Object.assign({}, this.props.data.projects)
    let { hash } = this.props.location

    if (typeof hash === 'string' && hash.length) {
      let category = hash.split('#')[1]
      projects.edges = projects.edges.filter(el => el.node.acf.category === category)
    }
    // Split projects into 3 columns
    let i = -1, l = projects.edges.length, cols = [[],[],[]]
    while (++i < l) cols[i % 3].push(projects.edges[i])

    return (
      <>
        <SEO title='Projects' bodyClass='project__archive__body' />
        <section className='project__archive'>
          <div>{ cols[0].map(this.renderItem) }</div>
          <div>{ cols[1].map(this.renderItem) }</div>
          <div>{ cols[2].map(this.renderItem) }</div>
        </section>
      </>
    )
  }
}

export default ProjectsPage

export const query = graphql`
  query {
    projects: allWordpressWpProject(filter:{wordpress_id:{ne:321}}) {
      edges {
        node {
          id
          slug
          title
          acf {
            category
            subtitle
            introduction
            feature_image {
              ...original
            }
          }
        }
      }
    }
  }
`
